import React from "react";
import "circular-std";
import styled from "styled-components";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { CenteredMainTitle } from "../../components/intro/common";
import { StyledA } from "../../components/header/common";
import { QuoteIconBg, TriangleProblemIcon, TriangleSolutionIcon, TriangleResultsIcon } from "../../components/Icons";
import { CaseStudyHeading } from "../../components/common";
import { baseWhiteButton } from "../../components/button";
import { Link } from "gatsby";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1040px;
`;

// Background Style

const Background = styled.div`
  background-color: ${(porps) => (porps.hero ? '#F8F6FF' : 'transparent')};
  z-index: ${(porps) => (porps.hero ? '-1' : '0')};
  height: ${(porps) => (porps.hero ? '800px' : '100%')};
  position: absolute;
  width: 100vw;
  top: 0px;
  left: 0px;
  overflow: hidden;
`;

const BoxOverlay = styled.div`
  opacity: 0.56;
  transform: rotate(-45deg);
  background-image: linear-gradient(0deg, #F4F0FF 25%, #FFFFFF 100%);
  box-shadow: -55px 110px 250px rgba(108,101,130,0.18);
  border-radius: 157px;
  width: 693px;
  height: 693px;
  position: absolute;
  @media only screen and (max-width: 1366px) {
    border-radius: 100px;
    width: 599px;
    height: 599px;
  }
  @media only screen and (max-width: 1279px) {
    border-radius: 69px;
    width: 499px;
    height: 499px;
  }
  @media only screen and (max-width: 767px) {
    border-radius: 59px;
    width: 399px;
    height: 399px;
  }
`;

const BoxOverlayOne = styled(BoxOverlay)`
  position: absolute;
  top: -8%;
  left: -14%;
  z-index: -3;
  @media only screen and (max-width: 1023px) {
    top: 25%;
    left: -35%;
  }
  @media only screen and (max-width: 767px) {
    top: 20%;
    left: -80%;
  }
`;

const BoxOverlayTwo = styled(BoxOverlay)`
  top: -40%;
  right: 12%;
  z-index: -2;
  @media only screen and (max-width: 767px) {
    top: -20%;
    right: -4%;
  }
`;

const BoxOverlaySmall = styled.div`
  opacity: 0.56;
  transform: rotate(-45deg);
  background-image: linear-gradient(0deg, #F4F0FF 25%, #FFFFFF 100%);
  box-shadow: 167px 110px 250px rgba(108,101,130,0.18);
  border-radius: 64px;
  width: 499px;
  height: 499px;
  position: absolute;
  bottom: -20%;
  left: 30%;
  z-index: -1;
  @media only screen and (max-width: 1279px) {
    width: 399px;
    height: 399px;
    left: 26%;
  }
  @media only screen and (max-width: 767px) {
    bottom: -20%;
    right: -10%;
  }
`;

const BoxOverlayBlue = styled.div`
  transform: rotate(-45deg);
  background-image: linear-gradient(0deg, #8598FA 0%, #5F81E6 100%);
  border-radius: 64px;
  width: 499px;
  height: 499px;
  position: absolute;
  bottom: -20%;
  right: -10%;
  z-index: 0;
  @media only screen and (max-width: 1279px) {
    width: 399px;
    height: 399px;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const SectionOverlay = styled.div`
  opacity: 0.56;
  transform: rotate(-45deg);
  background-image: linear-gradient(90deg, #9396FF 25%, #5E81E6 100%);
  background-blend-mode: overlay;
  border-radius: 64px;
  width: 499px;
  height: 499px;
  position: absolute;
  @media only screen and (max-width: 1279px) {
    width: 399px;
    height: 399px;
  }
`;

const SectionOverlayOne = styled(SectionOverlay)`
  top: 50%;
  left: 0;
  z-index: 3;
  @media only screen and (max-width: 1023px) {
    left: -20%;
  }
  @media only screen and (max-width: 767px) {
    left: -70%;
    top: 25%;
  }
`;

const SectionOverlayTwo = styled(SectionOverlay)`
  top: -53%;
  left: 30%;
  z-index: 2;
  @media only screen and (max-width: 1023px) {
    top: -45%;
    left: 26%;
  }
  @media only screen and (max-width: 767px) {
    top: -25%;
  }
`;

const SectionOverlayThree = styled(SectionOverlay)`
  top: 50%;
  left: 60%;
  z-index: 1;
  @media only screen and (max-width: 1023px) {
    left: 70%;
  }
  @media only screen and (max-width: 767px) {
    left: 60%;
    top: 60%;
  }
`;

// Hero Section Style

const HeroBlock = styled.section`
  padding: 120px 0px;
  @media only screen and (max-width: 767px) {
    padding: 0px 0px 50px 0px;
  }
`;

const MainTitle = styled(CenteredMainTitle)`
  font-family: CircularStd;
  font-weight: 500;
  letter-spacing: -1.45px;
  color: #1F2431;
  span{
    color: #798BF3;
  }
  @media only screen and (max-width: 767px) {
    font-size: 38px;
    line-height: 48px;
    font-weight: 500;
  }
`;

const IntroSection = styled.div`
  position: relative;
  margin-bottom: 80px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const IntroContent = styled(Container)`
  margin: 0px auto;
  position: relative;
`;

const Information = styled.div`
  text-align: center;
  color: #30323D;
  letter-spacing: 0.2px;
  text-align: center;
  line-height: 31px;
`;

const InformationLabel = styled.div`
  font-family: CircularStd;
  color: #575E72;
  letter-spacing: -0.35px;
  margin-bottom: 10px;
`;

const InformationLink = styled(StyledA)`
  font-weight: 500;
  color: #798BF3;
  margin: 0 4px;
  padding: 0px;
`;

// Content Style

const SectionBlock = styled.section`
  position: relative;
  overflow: ${(porps) => (porps.gradient ? 'hidden' : '')};
  z-index: ${(porps) => (porps.gradient ? '0' : '1')};
  background-image: ${(porps) => (porps.gradient ? 'linear-gradient(90deg, #9395ff 0%, #5e81e6 100%)' : 'none')};
  padding: ${(porps) => (porps.lastChild ? '50px 0px 0px' : '50px 0px')};
`;

const SectionContent = styled(Container)`
  margin: 0px auto;
  position: relative;
  z-index: 1;
`;

const NumbersTitle = styled.h2`
  font-family: CircularStd;
  font-weight: 400;
  font-size: 28px;
  letter-spacing: -1.18px;
  color: #FFF;
  margin: 50px auto 0px;
  @media only screen and (max-width: 767px) {
    margin: 0px;
  }
`;

const NumbersCard = styled.div`
  text-align: center;
`;

const Grid = styled.div`
  display: grid;

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridCol = styled.div`
  margin: 50px auto;
  padding: 0px 10px;
  @media only screen and (max-width: 767px) {
    margin: 40px auto;
  }
  p{
    font-family: CircularStd;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #E5EBFF;
    display: block;
    margin: 0px;
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const Stat = styled.div`
  font-family: CircularStd;
  font-weight: 700;
  font-size: 72px;
  color: #FFF;
  letter-spacing: -2.35px;
  text-align: center;
  line-height: 72px;
  @media only screen and (max-width: 1023px) {
    font-size: 56px;
    line-height: 56px;
  }
`;

const CaseStudySection = styled.div`
  position: relative;
  background: ${(porps) => (porps.lightblue ? '#E5E8FC' : 'transparent')};
  padding:  ${(porps) => (porps.mixed ? '100px 0px 50px 0px' : '50px 0px')};
  z-index: ${(porps) => (porps.mixed ? '0' : '2')};
  @media only screen and (max-width: 767px) {
    padding:  ${(porps) => (porps.mixed ? '120px 0px 50px 0px' : '0px 0px 50px 0px')};
  }
  .casestudy-thumbnail{
    max-width: 100%;
    margin-top: 20px;
  }
`;

const ImgRounded = styled.div`
  background: #ffffff;
  box-shadow: 0 60px 60px rgba(26,27,34,0.2);
  border-radius: 60px 0px 60px 0px;
  margin-bottom: -100px;
  line-height: 0;
  position: relative;
  overflow: hidden;
  img{
    max-width: 100%;
  }
  @media only screen and (max-width: 767px) {
    border-radius: 30px 0px 30px 0px;
  }
`;

const ThumbnailInfo = styled.div`
  font-family: CircularStd;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #AAB0BE;
  padding: 40px 0px 0px;
  @media only screen and (max-width: 767px) {
    padding: 10px 0px 0px;
  }
`;

const CaseStudyContainer = styled.div`
  max-width: 880px;
  margin: auto;
  padding: 0 20px;
`;

const Paragraph = styled.div`
  font-family: CircularStd;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.56px;
  color: #727B93;
  margin-bottom: 50px;
  p{
    margin: 40px 0px 0px;
    @media only screen and (max-width: 767px) {
      margin: 20px 0px 0px;
    }
    span{
      color: #6673EE;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const QuoteIcon = styled(QuoteIconBg)`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.1;
  z-index: 0;
`;

const QuoteContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 60px 60px rgba(26,27,34,0.2);
  border-radius: 0px 0px 60px 0px;
  max-width: 640px;
  margin-bottom: -100px;
  padding: 60px;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 767px) {
    padding: 30px;
    border-radius: 0px 0px 30px 0px;
  }
`;

const Quote = styled.div`
  font-family: CircularStd;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.568421px;
  color: #727B93;
  span{
    color: #798BF3;
  }
`;

const Signature = styled.div`
  font-family: CircularStd;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #798BF3;
  margin-top: 60px;
`;

const SignaturePost = styled.span`
  opacity: 0.5;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #727B93;
  display: block;
`;

const CtaBlock = styled.div`
  max-width: 680px;
  margin: 0px auto;
  text-align: center;
`;

const CtaTitle = styled.h3`
  font-family: CircularStd;
  font-weight: 600;
  font-size: 49px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #FFFFFF;
  margin: 80px auto 40px;
`;

const CtaDemoLink = styled(Link)`
  ${baseWhiteButton}
  margin-bottom: 80px;
`;

const LogoImage = styled.img`
  max-width: 300px;
`;

const CaseStudyDetails = ({ pageContext: { item = {} } }) => {
  let itemFileData = {}
  try {
    itemFileData = require(`./../../data/casestudies/${item.dataFile}.yaml`);
  } catch (e) { }
  const { intro = {}, summary = {}, keyStats = {}, problem = {}, solution = {}, result = {}, quote = {}, ctaTitle = '' } = itemFileData || {};
  return (
    <Layout
      navBackgrounds={[
        <Background hero key='Background'>
          <BoxOverlayOne />
          <BoxOverlayTwo />
          <BoxOverlaySmall />
          <BoxOverlayBlue />
        </Background>,
      ]}
      footerBackgroundColor={'#777'}
      footerBackgroundImg={'linear-gradient(90deg, #9395ff 0%, #5e81e6 100%)'}
      hideDemo
    >
      <SEO
        title="MSG91 Case Study"
        keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
      />

      <HeroBlock>
        <IntroSection id="headline">
          <IntroContent>
            <MainTitle dangerouslySetInnerHTML={{ __html: intro.title }}></MainTitle>
          </IntroContent>
        </IntroSection>
        <Container>
          <Information>
            <InformationLabel>
              {summary.forLabel} <InformationLink href={summary.url}>{summary.name}</InformationLink>
            </InformationLabel>
            {
              summary.image ?
                <LogoImage
                  className="lazy"
                  src={summary.image.url}
                  alt={summary.image.alt}
                /> : null
            }
          </Information>
        </Container>
      </HeroBlock>

      <SectionBlock gradient>
        <SectionContent>
          <NumbersCard>
            <NumbersTitle>{keyStats.title}</NumbersTitle>
            <Grid>
              <GridCol>
                <Stat>{keyStats.chatResponse?.count}</Stat>
                <p>{keyStats.chatResponse?.title}</p>
              </GridCol>
              <GridCol>
                <Stat>{keyStats.resolveSupport?.count}</Stat>
                <p>{keyStats.resolveSupport?.title}</p>
              </GridCol>
              <GridCol>
                <Stat>{keyStats.customerInquries?.count}</Stat>
                <p>{keyStats.customerInquries?.title}</p>
              </GridCol>
            </Grid>
          </NumbersCard>
        </SectionContent>
        <Background>
          <SectionOverlayOne />
          <SectionOverlayTwo />
          <SectionOverlayThree />
        </Background>
      </SectionBlock>

      <SectionBlock lastChild id="study">
        <div className="percept-content-case-study-msg91">
          <div className="percept-case-study">

            <CaseStudySection id="problem">
              <Container>
                <TriangleProblemIcon />
              </Container>
              <CaseStudyContainer>
                <CaseStudyHeading>The Problem</CaseStudyHeading>
                <Paragraph dangerouslySetInnerHTML={{ __html: problem.text }}></Paragraph>
              </CaseStudyContainer>
              <img
                className="casestudy-thumbnail"
                src={`${problem.thumbnail}`}
                alt="Problem Thumbnail"
              />
              {problem.source &&
                <Container>
                  <ThumbnailInfo>
                    Photo from: <InformationLink href={problem.sourceUrl} target="_blank">{problem.source}</InformationLink>
                  </ThumbnailInfo>
                </Container>
              }
            </CaseStudySection>

            <CaseStudySection id="solution">
              <Container>
                <TriangleSolutionIcon />
              </Container>
              <CaseStudyContainer>
                <CaseStudyHeading>The Solution</CaseStudyHeading>
                <Paragraph dangerouslySetInnerHTML={{ __html: solution.text }}></Paragraph>
              </CaseStudyContainer>
              {solution.thumbnail && (
                <Container>
                  <ImgRounded>
                    <img
                      className="rounded-thumbnail"
                      src={solution.thumbnail}
                      alt="Solution Thumbnail"
                    />
                  </ImgRounded>
                </Container>
              )}

            </CaseStudySection>

            <CaseStudySection lightblue mixed id="results">
              <Container>
                <TriangleResultsIcon />
              </Container>
              <CaseStudyContainer>
                <CaseStudyHeading>The Results</CaseStudyHeading>
                <Paragraph dangerouslySetInnerHTML={{ __html: result.text }}></Paragraph>
                <QuoteContainer>
                  <Quote dangerouslySetInnerHTML={{ __html: quote.text }}></Quote>
                  <Signature>{quote.name}, <SignaturePost>{quote.Signature}</SignaturePost></Signature>
                </QuoteContainer>
              </CaseStudyContainer>
              <QuoteIcon />
            </CaseStudySection>

          </div>
        </div>
      </SectionBlock>
      <SectionBlock gradient>
        <SectionContent>
          <CtaBlock>
            <CtaTitle>{ctaTitle}</CtaTitle>
          </CtaBlock>
        </SectionContent>
        <Background>
          <SectionOverlayOne />
          <SectionOverlayTwo />
          <SectionOverlayThree />
        </Background>
      </SectionBlock>
    </Layout>
  )
};

export default CaseStudyDetails;
